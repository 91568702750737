<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Audi Page</h1>
    <Hero heading="Power the potential of your fully electric Audi e-tron" headlineLevel="h2" :subheading="true">
      <template #subheading>
        Conquer the roads ahead with the benefits of an Audi charging bundle included with the purchase or lease of your
        new Audi
        <span class="wrap-last-two-words"
          >e-tron<sup>® </sup>
          <ReferenceLink num="1">
            Requires acceptance of Promotion Terms & Conditions, myAudi account and myAudi app. Non-transferable. Not
            available for commercial use, such as ridesharing. In the event of suspected fraud or abuse or other
            unforeseen events, Audi of America may discontinue or modify the offer in its sole discretion. Frequent and
            consecutive charging can permanently decrease battery capacity, which may not be covered by your vehicle’s
            warranty.</ReferenceLink
          ></span
        >
      </template>
      <template #image>
        <img
          src="@/assets/images/Partners/Etron/etron-image1.png"
          alt="Audi Etron EV charging at an Electrify America charging station"
        />
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" headlineLevel="h3">
      <p>
        Electrify America has constructed a convenient and reliable Ultra-Fast network of DC fast charging
        stations&mdash;located along many major highways and across the continental U.S. On the open roads, Electrify
        America helps get electric vehicle (EV) drivers where they want and need to go&mdash;because Audi electric
        vehicles are made for every journey and any adventure.
      </p>
      <PartnerDropdown id="etron-select" v-model="modelChosen" :options="etronDropdownOptions">
        <template #label>
          <div>
            <label for="etron-select">Select your Audi e-tron model </label>
            <ReferenceLink num="2">Only applicable on purchase of a new vehicle.</ReferenceLink>
          </div>
        </template>
      </PartnerDropdown>
      <p class="bundles"><strong>Audi charging bundles include: </strong></p>
      <section class="model-details">
        <p v-if="modelChosen === '1'">
          Your 2019-2021 Audi e-tron / e-tron Sportback comes with an available 1000 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '2'">
          Your 2022 Audi e-tron / e-tron S comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '3'">
          Your 2022 Audi e-tron GT / Audi RS e-tron GT comes with an available 3 years of unlimited charging energy!
        </p>
        <p v-if="modelChosen === '4'">
          Your 2022 Audi e-tron Sportback / Audi e-tron S Sportback comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '5'">Your 2022 Audi Q4 e-tron comes with an available 250 kWh of charging energy!</p>
        <p v-if="modelChosen === '6'">
          Your 2022 Audi Q4 Sportback e-tron comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '7'">
          Your 2023 Audi e-tron / e-tron S comes with an available 250 kWh of charging energy.
        </p>
        <p v-if="modelChosen === '8'">
          Your 2023 Audi e-tron GT / Audi RS e-tron GT comes with an available 3 years of unlimited charging energy!
        </p>
        <p v-if="modelChosen === '9'">Your 2023 Audi Q4 e-tron comes with an available 250 kWh of charging energy!</p>
        <p v-if="modelChosen === '10'">
          Your 2023 Audi e-tron Sportback / Audi e-tron S Sportback comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '11'">
          Your 2023 Audi Q4 Sportback e-tron comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '12'">
          Your 2024 Audi Q8 e-tron / Q8 Sportback e-tron comes with an available 2 years of unlimited charging energy!
        </p>
        <p v-if="modelChosen === '13'">
          Your 2024 Audi SQ8 e-tron / SQ8 Sportback e-tron comes with an available 2 years of unlimited charging energy!
        </p>
        <p v-if="modelChosen === '14'">
          Your 2024 Audi e-tron GT / Audi RS e-tron GT includes 2 years of DC fast (CCS) and Level 2 charging.
        </p>
        <p v-if="modelChosen === '15'">
          Your 2024 Audi Q4 e-tron / Q4 e-tron Sportback comes with an available 250 kWh of charging energy!
        </p>
        <p v-if="modelChosen === '17'">
          Your 2025 Audi Q4 e-tron / Q4 e-tron Sportback comes with an available 250 kWh of charging energy!
        </p>
      </section>
      <p>With these benefits, Audi drivers can explore the full potential of electric freedom.</p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go with freedom" headlineLevel="h3">
      <template #image>
        <img
          loading="lazy"
          src="@/assets/images/Partners/Etron/etron-image2.png"
          alt="Close up of an E-tron charger port with an Electrify America charger plugged into it"
        />
      </template>
      <p>
        Get on the road with the benefits included in your Audi charging plan on Electrify America’s DC Fast Charging
        network.
      </p>
      <p>
        <strong
          >Charging is simple. Just follow these
          <span class="wrap-last-two-words"
            >steps: <ReferenceLink num="3">Charging steps are subject to change.</ReferenceLink></span
          >
        </strong>
      </p>
      <ul>
        <li>
          <strong>Step 1:</strong> Locate an Electrify America charging station using the myAudi
          <span class="wrap-last-two-words"
            >App
            <ReferenceLink num="4"
              >Message and data rates apply. Always pay careful attention to the road, and do not drive while
              distracted. Charging steps are subject to change.</ReferenceLink
            ></span
          >
          or your Audi vehicle's MMI
          <span class="wrap-last-two-words"
            >touchscreen.<ReferenceLink num="5"
              >Always pay careful attention to the road, and do not drive while distracted. See Owner’s Manual for
              further details, and important limitations.
            </ReferenceLink></span
          >
        </li>
        <li><strong>Step 2:</strong> Plug in your Audi vehicle.</li>
        <li>
          <strong>Step 3:</strong>
          Open the myAudi App and enter the charging station ID to start your session.
        </li>
        <li><strong>Step 4:</strong> The myAudi App will notify you when your charge is complete.</li>
      </ul>
      <p>
        <strong>Questions?</strong> Call <strong>1-833-632-2778</strong> to contact Electrify America Customer
        Assistance, available 24/7.
      </p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  components: {
    ReferenceLink,
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'Audi e-tron Electric Vehicle | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/audi/' }],
  },
  data() {
    return {
      etronDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2025 Audi Q4 e-tron / Q4 e-tron Sportback', value: '17' },
        { text: '2024 Audi Q4 e-tron / Q4 e-tron Sportback', value: '15' },
        { text: '2024 Audi e-tron GT / Audi RS e-tron GT', value: '14' },
        { text: '2024 Audi SQ8 e-tron / SQ8 Sportback e-tron', value: '13' },
        { text: '2024 Audi Q8 e-tron / Q8 Sportback e-tron', value: '12' },
        { text: '2023 Audi Q4 Sportback e-tron', value: '11' },
        { text: '2023 Audi e-tron Sportback / Audi e-tron S Sportback', value: '10' },
        { text: '2023 Audi Q4 e-tron', value: '9' },
        { text: '2023 Audi e-tron GT / Audi RS e-tron GT', value: '8' },
        { text: '2023 Audi e-tron / e-tron S', value: '7' },
        { text: '2022 Audi Q4 Sportback e-tron', value: '6' },
        { text: '2022 Audi Q4 e-tron', value: '5' },
        { text: '2022 Audi e-tron Sportback / Audi e-tron S Sportback ', value: '4' },
        { text: '2022 Audi e-tron GT / Audi RS e-tron GT', value: '3' },
        { text: '2022 Audi e-tron / e-tron S', value: '2' },
        { text: '2019-2021 Audi e-tron / e-tron Sportback', value: '1' },
      ],
      modelChosen: null,
    };
  },
};
</script>
